import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

if(document.body.classList.contains('main-page') && window.innerWidth >= 1200){
    gsap.to(".intro__image", {
      scrollTrigger: {
        trigger: ".intro",
        scrub: 0.9,
        start: "top bottom",
        end: "bottom -100%",
        ease: "power2"
      },
      y: "-15%"
    });
    
    gsap.to(".tagline__decor.--ladybag", {
      scrollTrigger: {
        trigger: ".tagline",
        scrub: 0.6,
        start: "-300% top",
        end: "bottom -100%",
        ease: "power2"
      },
      y: "-50%",
      x: "60%"
    });
    
    gsap.to(".tagline__decor.--butterfly", {
      scrollTrigger: {
        trigger: ".tagline",
        scrub: 0.6,
        start: "-300% top",
        end: "bottom -300%",
        ease: "power2"
      },
      y: "55%",
      x: "25%"
    });
    
    gsap.set(".assortiment__image", {y: "-30%"});
    gsap.to(".assortiment__image", {
      scrollTrigger: {
        trigger: ".assortiment",
        scrub: 0.6,
        start: "-50% top",
        end: "bottom 10%",
        ease: "power2"
      },
      y: "-55%",
    });
    
    gsap.set(".about__col-image", {y: "20%"});
    gsap.to(".about__col-image", {
      scrollTrigger: {
        trigger: ".about",
        scrub: 0.6,
        start: "-50% top",
        end: "bottom 10%",
        ease: "power2"
      },
      y: "-15%",
    });
}