import './components/parallax';
import './components/slider';
// import 'video.js';
import ScrollMagic from 'scrollmagic';

const controller = new ScrollMagic.Controller();

// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : '';

function autoplayVideos() {
    let videos = document.querySelectorAll('.js-video-autoplay');

    if (!videos.length) {
        return;
    }

    for (let i = 0; i < videos.length; i++) {
        let video = videos[i];
        let height = video.offsetHeight;

        new ScrollMagic.Scene({
            triggerElement: video,
            triggerHook: 1,
            offset: height,
            duration: '100%',
            reverse: true

        })
            .on('enter', function () {
                video.play();
            })

            .on('leave', function () {
                video.pause();
            })
            .addTo(controller);

    }
    console.log('Yeah!')
}

    autoplayVideos();

document.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('resize', () => {
        window.headerHeight();
        autoplayVideos();
    });
    autoplayVideos();

    // Change header background at scroll
    window.onscroll = function (e) {
        if (window.pageYOffset > 50) {
            document.body.classList.add('scrolled');
        } else {
            document.body.classList.remove('scrolled');
        }
    };


    // const openLangDropdown = document.querySelector('.lang-switch__arrow');

    // openLangDropdown.addEventListener('click', () => {
    //     openLangDropdown.parentElement.parentElement.classList.toggle('is-open')
    // });
});


// Mob menu
(function () {
    let trigger = document.querySelector('.js-menu-trigger'),
        mobMenu = document.querySelector('.lang-switch');
    if (trigger) {
        document.addEventListener('click', function (event) {
            if (mobMenu && document.body.classList.contains('menu-open')) {
                var isClickInside = mobMenu.contains(event.target);
                if (!isClickInside && !trigger.contains(event.target)) {
                    document.body.classList.remove('menu-open');
                }
            }
        });
        trigger.onclick = () => {
            document.body.classList.toggle('menu-open');
        };
    }
})();
